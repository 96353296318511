import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthProvider } from './services/AuthContext';


import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard'; // Import Dashboard component
import Layout from './components/Layout'; //import layout component
import CheckIn from './components/CheckIn';
import LogoutButton from './components/Logout';
import CheckOut from './components/Checkout';
import SuccessCheckIn from './components/SuccessCheckIn';
import SuccessCheckOut from './components/SuccessCheckOut';
import Attendance from './components/Attendance';
import UserReport from './components/UserReport'
import PrivateRoute from './controllers/privateRoutes';
import UpdateUser from './components/UpdateUser';
import EmailReport from './views/EmailReport';
import Settings from './views/SettingView';


const App = () => {
  return (
    <AuthProvider>
  
        <div>
        <Router>
          
   
          <Routes>
         <Route path='/' element={<Login/>}/>
            <Route path="/register" element={<Register />} />
           
              <Route element={<Layout />}>
                <Route path="/checkIn" element={<CheckIn />} />
                <Route path="/success/checkin" element={<SuccessCheckIn />} />
                <Route path="/success/checkout" element={<SuccessCheckOut />} />
                <Route path="/checkout" element={<CheckOut />} />
                <Route path='/attendance' element={<Attendance />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/logout" element={<LogoutButton />} />
                <Route path="/UserAttendance" element={<UserReport />} />
              <Route path="/UpdateUser" element={<UpdateUser />} />
              <Route path="/attendance" element={<EmailReport />} />
                <Route path="/settings" element={<Settings/>}/>
              </Route>
          </Routes>
        </Router>
        </div>
   
    </AuthProvider>
  );
};

export default App;


