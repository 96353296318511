import React from 'react';
import LoginView from '../views/LoginView';

const Login = () => {
  return (
    <div className="container">
      <LoginView />
    </div>
  );
};

export default Login;
