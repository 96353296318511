import React from 'react';
import GenerateEmail from '../components/GenerateEmail';



const EmailReport = () => {



   

    return (
        <>
        <GenerateEmail/>
        </>
    );
};

export default EmailReport;