import React, { useState, useEffect } from 'react';
import UAParser from 'ua-parser-js';

const UserAgentInfo = () => {
  const [userAgentInfo, setUserAgentInfo] = useState({});

  useEffect(() => {
    // Parse the user agent string
    const parser = new UAParser();
    const result = parser.getResult();

    // Set the user agent information in the state
    setUserAgentInfo({
      browser: `${result.browser.name} ${result.browser.version}`,
      os: `${result.os.name} ${result.os.version}`,
      device: result.device.model ? `${result.device.vendor} ${result.device.model}` : 'Desktop',
    });
  }, []);

  return (
    <div className="container mt-1">
      <h1 className="text-center font-weight-bold mb-4" style={{ color: '#6c757d' }}>User Device Information</h1>
      <div className="row">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card bg-primary shadow text-center">
            <div className="card-body">
              <h5 className="card-title font-weight-bold mb-0" style={{ fontSize: '1.5rem' }}>Browser</h5>
              <p className="card-text text-white">{userAgentInfo.browser}</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <div className="card bg-success shadow text-center">
            <div className="card-body">
              <h5 className="card-title font-weight-bold mb-0" style={{ fontSize: '1.5rem' }}>OS</h5>
              <p className="card-text text-white">{userAgentInfo.os}</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="card bg-danger shadow text-center">
            <div className="card-body">
              <h5 className="card-title font-weight-bold mb-0" style={{ fontSize: '1.5rem' }}>Device</h5>
              <p className="card-text text-white">{userAgentInfo.device}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAgentInfo;
