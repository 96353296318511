import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';
import { useNavigate } from 'react-router-dom';

const UpdateUserRoleForm = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState(["superadmin", 'super', "admin"]); //roles
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        // Fetch users from the API
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${API_URL}/users/users`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.put(`${API_URL}/users/role`, {
                userId: selectedUser,
                role: selectedRole
            });
            alert('User role updated successfully');
            navigate('/dashboard'); // Redirect to dashboard
        } catch (error) {
            console.error('Error updating user role:', error);
            alert('Failed to update user role');
        }
    };

    return (
        <div className="card m-3">
            <div className="card-body">
                <h5 className="card-title">Update User Role</h5>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="userSelect" className="form-label">Select User</label>
                        <select
                            id="userSelect"
                            className="form-select"
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                        >
                            <option value="">Select a user</option>
                            {users.map((user) => (
                                <option key={user._id} value={user._id}>
                                    {user.fullname} ({user.email})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="roleSelect" className="form-label">Select Role</label>
                        <select
                            id="roleSelect"
                            className="form-select"
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value)}
                        >
                            <option value="">Select a role</option>
                            {roles.map((role) => (
                                <option key={role} value={role}>
                                    {role}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button type="submit" className="btn btn-primary">Save</button>
                </form>
            </div>
        </div>
    );
};

export default UpdateUserRoleForm;
