import React from 'react';

import UserAgentInfo from './UserAgentInfo';
import AttendanceView from '../views/AttendanceView';

const Attendance = () => {
    return (
        <div className="container">
            <div className='col-md-12'><UserAgentInfo /></div>
            <AttendanceView  />
        </div>
    );
};

export default Attendance;
