import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';
import { useNavigate } from 'react-router-dom';

const CheckInView = () => {
  const [formData, setFormData] = useState({
    userId: '',
    latitude: '',
    longitude: '',
    branchId: ''
  });

  const navigate = useNavigate();

  const [branches, setBranches] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  // Retrieve device ID from cookie and update form state
  const getCookieData = () => {
    const deviceId = getCookie('deviceId');
    if (deviceId) {
      setFormData(prevData => ({ ...prevData, deviceId }));
    }
  };

  // Function to get cookie value by name
  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  useEffect(() => {
    fetchBranches();
    getUserLocation();
    fetchLoggedInUser();
    getCookieData();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/branches/branches`);
      setBranches(response.data.branches);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const fetchLoggedInUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${API_URL}/users/loggedInUser`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFormData(prevData => ({
        ...prevData,
        userId: response.data._id
      }));
    } catch (error) {
      console.error('Error fetching logged-in user:', error);
    }
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/attendance/recordAttendance`, formData);
      setMessage(response.data.message);
      navigate('/success/checkin');
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setFormData(prevData => ({
            ...prevData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }));
        },
        error => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Radius of the Earth in meters
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
  };

  const isUserWithinRadius = (selectedBranch) => {
    const { latitude, longitude } = formData;
    if (!latitude || !longitude) {
      return false;
    }

    const distance = calculateDistance(
      parseFloat(latitude),
      parseFloat(longitude),
      parseFloat(selectedBranch.latitude),
      parseFloat(selectedBranch.longitude)
    );
    console.log('Distance:', distance);
    console.log('Radius:', selectedBranch.radius);
    console.log('Is within radius:', distance <= selectedBranch.radius);
    return distance <= selectedBranch.radius;
  };

  useEffect(() => {
    console.log('Latitude:', formData.latitude);
    console.log('Longitude:', formData.longitude);
    console.log('Branch ID:', formData.branchId);
    console.log('Branches:', branches); // Log branches array for debugging
    if (formData.latitude && formData.longitude && formData.branchId) {
      setError('');
    } else {
      setError('Please fill out all fields.');
      setCanSubmit(false);
      return; // Exit early if any of the required fields are missing
    }

    console.log('Branches:', branches);
    console.log('formData.branchId:', formData.branchId);
    console.log('Form Data Branch ID:', formData.branchId);
    const selectedBranch = branches.find((branch) => branch._id === formData.branchId);

    console.log('Selected Branch:', selectedBranch); // Log IDs being compared

    if (!selectedBranch) {
      setError('Selected branch not found in branch data.');
      setCanSubmit(false);
      return; // Exit early if branch data is not found
    }

    const withinRadius = isUserWithinRadius(selectedBranch);
    setCanSubmit(withinRadius);
    if (!withinRadius) {
      setError('You are not within the office radius.');
    }
  }, [formData.latitude, formData.longitude, formData.branchId, branches]);

  return (
    <div className="container mt-5">
      <div className='card'>
        <div className='card-header'>
          <h2>Check-In for today</h2>
        </div>
        <div className='card-body'>


          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="userId" className="form-label">User ID:</label>
              <input
                type="text"
                className="form-control"
                id="userId"
                name="userId"
                value={formData.userId}
                onChange={handleChange}
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="deviceId" className="form-label">Device ID:</label>
              <input
                type="text"
                className="form-control"
                id="deviceId"
                name="deviceId"
                value={formData.deviceId}
                onChange={handleChange}
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="branchId" className="form-label">Branch:</label>
              <select
                className="form-select"
                id="branchId"
                name="branchId"
                value={formData.branchId}
                onChange={handleChange}
              >
                <option value="">Select Branch</option>
                {branches.map((branch) => (
                  <option key={branch._id} value={branch._id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
            {formData.branchId && (
              <>
                <div className="mb-3">
                  <label htmlFor="latitude" className="form-label">Latitude:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="latitude"
                    name="latitude"
                    value={formData.latitude}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="longitude" className="form-label">Longitude:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="longitude"
                    name="longitude"
                    value={formData.longitude}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="mb-3">
              <button type="submit" className="btn btn-primary" disabled={!canSubmit}>
                CheckIn
              </button>
            </div>
            {error && <div className="text-danger">{error}</div>}
            {message && <div className="text-success">{message}</div>}
          </form>
        </div>
       
        
      <div className='card-footer'>
        <small>
          Note: Remember to checkout at the end of the day
        </small>

      </div>
      </div>
    </div>
  );
};

export default CheckInView;