import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';

export default function UserAttendanceView() {
  const [attendance, setAttendance] = useState([]);
  const [loggedInUserId, setLoggedInUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10); // Number of records per page

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await axios.get(`${API_URL}/users/loggedInUser`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setLoggedInUserId(response.data._id);
        setUserName(response.data.fullname); // Assuming fullname is part of user data
      } catch (error) {
        console.error('Error fetching logged-in user:', error);
      }
    };

    fetchLoggedInUser();
  }, []);

  useEffect(() => {
    const fetchAttendanceByUser = async () => {
      try {
        const response = await axios.get(`${API_URL}/attendance/user/${loggedInUserId}`);
        setAttendance(response.data);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      }
    };

    if (loggedInUserId) {
      fetchAttendanceByUser();
    }
  }, [loggedInUserId]);

  const calculateHoursWorked = (checkInTime, checkOutTime) => {
    if (!checkOutTime) return 'N/A';

    const checkIn = new Date(checkInTime);
    const checkOut = new Date(checkOutTime);

    const diffInMilliseconds = checkOut - checkIn;
    const diffInMinutes = diffInMilliseconds / 1000 / 60; // difference in minutes

    const hours = Math.floor(diffInMinutes / 60); // get whole hours
    const minutes = Math.floor(diffInMinutes % 60); // get remaining minutes

    return `${hours} hr${hours !== 1 ? 's' : ''} : ${minutes} min${minutes !== 1 ? 's' : ''}`;
  };

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = attendance.slice(indexOfFirstRecord, indexOfLastRecord);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='card'>


        <div className="card-header">
          <h3 className="mt-4">Welcome, {userName}</h3>
        </div>
        <div className='card-body p-0'>


          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Date</th>
                  <th scope="col">Check-In Time</th>
                  <th scope="col">Check-Out Time</th>
                  <th scope="col">Hours Worked</th>
                  <th scope="col">Branch</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((record, index) => (
                  <tr key={record._id}>
                    <th scope="row">{index + indexOfFirstRecord + 1}</th>
                    <td>{new Date(record.checkIn).toLocaleDateString()}</td>
                    <td>{new Date(record.checkIn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                    <td>{record.checkOut ? new Date(record.checkOut).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }) : 'N/A'}</td>
                    <td>{calculateHoursWorked(record.checkIn, record.checkOut)}</td>
                    <td>{record.branchId.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  

      {/* Pagination Controls */}
      <div className='card-footer'>
      <nav>
        <ul className="pagination">
          {Array.from({ length: Math.ceil(attendance.length / recordsPerPage) }, (_, i) => (
            <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(i + 1)}>
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
        </nav>
        </div>
    </div>
  );
}
