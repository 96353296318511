import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import API_URL from '../config';

const CheckOutView = () => {
  const [userId, setUserId] = useState('');
  const [branchId, setBranchId] = useState('');
  const [branches, setBranches] = useState([]);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const deviceId = Cookies.get('deviceId');

  useEffect(() => {
    fetchBranches();
    fetchLoggedInUser();
  }, []);

  const fetchBranches = async () => {
    try {
      const res = await axios.get(`${API_URL}/branches/branches`);
      console.log('Fetched branches:', res.data);

      if (Array.isArray(res.data.branches)) {
        setBranches(res.data.branches);
      } else {
        console.error('Branches data is not an array:', res.data);
        setBranches([]);
        setError('Branches data is not an array');
      }
    } catch (err) {
      console.error('Error fetching branches:', err);
      setError('Error fetching branches');
    }
  };

  const fetchLoggedInUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${API_URL}/users/loggedInUser`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUserId(response.data._id); // Set the userId fetched from API
    } catch (error) {
      console.error('Error fetching logged-in user:', error);
    }
  };

  const handleUpdateAttendance = async () => {
    try {
      const res = await axios.post(`${API_URL}/attendance/checkOut`, { userId, branchId, deviceId });
      setResponse(res.data);
      setError(null);
    } catch (err) {
      console.error('Error updating attendance:', err);
      setError(err.response ? err.response.data.message : 'Error checking/updating attendance');
      setResponse(null);
    }
  };

  return (
    <div className="container mt-5">
      <div className='card'>

        <div className='card-header'>

          <h3 className="mb-4">Check Out</h3>
        </div>
        <div className='card-body'>
        <div className="form-group">
          <label>User ID:</label>
          <input
            type="text"
            className="form-control"
            value={userId}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Device ID:</label>
          <input
            type="text"
            className="form-control"
            value={deviceId}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Branch ID:</label>
          <select
            className="form-control"
            value={branchId}
            onChange={(e) => setBranchId(e.target.value)}
          >
            <option value="">Select Branch</option>
            {branches.map(branch => (
              <option key={branch._id} value={branch._id}>
                {branch.name}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-primary mt-3" onClick={handleUpdateAttendance} disabled={response !== null} >
          Check Out
          </button>
        </div>
        <div className='card-footer'>
        {error && (
          <div className="alert alert-danger mt-3">
            <h4>Error</h4>
            <p>{error}</p>
          </div>
        )}
        {response && (
          <div className="alert alert-success mt-3">
            <h4>Check Out Successful</h4>
            <p>{JSON.stringify(response)}</p>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckOutView;
