import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';

const LogoutButton = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    // Function to show the modal
    const handleShowModal = () => setShowModal(true);

    // Function to hide the modal
    const handleCloseModal = () => setShowModal(false);

    // Logout function
    const handleLogout = () => {
        // Perform any client-side cleanup here
        localStorage.removeItem('token'); // Clear locally stored token
        // Redirect to the login page
        navigate('/');
    }

    return (
        <>
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Row className="w-100">
                    <Col md={{ span: 6, offset: 3 }} className="d-flex justify-content-center">
                        <Button 
                            variant="danger" 
                            onClick={handleShowModal} 
                            className="btn-lg"
                            style={{ width: '100%', height: '100px', fontSize: '1.5rem' }}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleLogout}>
                        Yes, Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LogoutButton;
