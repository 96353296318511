import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaHome, FaBell, FaBellSlash, FaCalendarAlt, FaCog, FaKey, FaMailBulk } from 'react-icons/fa';
import logo from '../assets/mbale_logo.jpg';
import { useAuth } from '../services/AuthContext';

const Layout = () => {
  const { user } = useAuth();
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    if (user) {
      console.log('Logged in user:', user);
      console.log('User role:', user.role);

      const calculateTimeRemaining = () => {
        const tokenExpiry = localStorage.getItem('tokenExpiry');
        if (tokenExpiry) {
          const expiryDate = new Date(tokenExpiry);
          const now = new Date();
          const remainingTime = Math.max(0, Math.floor((expiryDate - now) / 1000));
          setTimeRemaining(remainingTime);
        }
      };

      calculateTimeRemaining();

      const timer = setInterval(() => {
        calculateTimeRemaining();
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [user]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div className="container-fluid">
      <div className="row min-vh-100 flex-column flex-md-row">
        <aside className="col-12 col-md-3 col-lg-2 p-0 bg-dark flex-shrink-1">
          <nav className="navbar navbar-dark align-items-start">
            <div className="container-fluid flex-column">
              <div className="text-center mb-3">
                <Link to="/" className="navbar-brand mb-3 rounded-full">
                  <img src={logo} alt="Logo" className="img-fluid w-50 rounded-circle d-grid m-auto" />
                </Link>
                <div className="text-white">
                  <small>Time Remaining: {formatTime(timeRemaining)}</small>
                </div>
              </div>

              <ul className="navbar-nav flex-column mb-auto m-3">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    <FaHome className="me-2" /> Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/checkIn" className="nav-link">
                    <FaBell className="me-2" /> CheckIn
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/checkout" className="nav-link">
                    <FaBellSlash className="me-2" /> CheckOut
                  </Link>
                </li>
                {user && (user.role === 'superadmin' || user.role === 'super') && (
                  <>
                    <li className="nav-item">
                      <Link to="/attendance" className="nav-link">
                        <FaCalendarAlt className="me-2" /> Attendance Report
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/settings" className="nav-link">
                        <FaCog className="me-2" /> Settings
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/UpdateUser" className="nav-link">
                        <FaCog className="me-2" /> Update Role
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/attendance" className="nav-link">
                        <FaMailBulk className="me-2" /> Email Report
                      </Link>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <Link to="/logout" className="nav-link">
                    <FaKey className="me-2" /> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </aside>

        <main className="col bg-faded py-3 flex-grow-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
export default Layout;
