import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { format, subDays } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import API_URL from '../config';

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AttendanceChartView = () => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const fetchAttendanceData = async () => {
            try {
                const response = await axios.get(`${API_URL}/attendance/all`);
                const data = response.data;

                const lastWeekDates = Array.from({ length: 7 }, (_, i) => subDays(new Date(), i))
                    .map(date => format(date, 'yyyy-MM-dd'))
                    .reverse();

                const attendanceCountsByBranch = lastWeekDates.map(date => {
                    const recordsForDate = data.filter(record => format(new Date(record.checkIn), 'yyyy-MM-dd') === date);
                    const branchCounts = recordsForDate.reduce((acc, record) => {
                        const branch = record.branch;
                        if (!acc[branch]) {
                            acc[branch] = 0;
                        }
                        acc[branch]++;
                        return acc;
                    }, {});
                    return branchCounts;
                });

                const branches = [...new Set(data.map(record => record.branch))];

                const datasets = branches.map(branch => {
                    return {
                        label: branch,
                        data: attendanceCountsByBranch.map(counts => counts[branch] || 0),
                        backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
                        borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
                        borderWidth: 1,
                    };
                });

                console.log('Last Week Dates:', lastWeekDates);
                console.log('Attendance Counts By Branch:', attendanceCountsByBranch);
                console.log('Datasets:', datasets);

                setChartData({
                    labels: lastWeekDates,
                    datasets: datasets,
                });
            } catch (error) {
                console.error('Error fetching attendance data:', error);
            }
        };

        fetchAttendanceData();
    }, []);

    if (!chartData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Attendance in the Past Week by Branch</h2>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                            beginAtZero: true,
                        },
                        y: {
                            stacked: true,
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1,
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default AttendanceChartView;
