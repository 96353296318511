import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import API_URL from '../config';
import logo from '../assets/mbale_logo.jpg'; // Import your logo image here

const LoginView = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/users/login`, { email, password }, { withCredentials: true });
            setSuccess('Login successful!');
            setError('');
            // Store the token in local storage or context
            localStorage.setItem('token', response.data.token);
            navigate('/dashboard'); // Redirect to dashboard page
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.message); // Display the error message from the backend
            } else {
                setError('Error logging in, check your network');
            }
            setSuccess('');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="card p-4 shadow" style={{ maxWidth: '400px', width: '100%' }}>
                <main className="form-signin">
                    <form onSubmit={handleSubmit}>
                        <div className="text-center mb-4">
                            <img src={logo} alt="Logo" width="150" height="130" />
                        </div>
                        <h1 className="h3 mb-3 fw-normal text-center">Please sign in</h1>
                        {error && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{success}</div>}
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="name@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <button className="w-100 btn btn-lg btn-primary mb-3" type="submit">Sign in</button>
                        <div className="text-center">
                            <p className="mb-0">New user? <Link to="/register">Register here</Link></p>
                        </div>
                    </form>
                </main>
            </div>
        </div>
    );
};

export default LoginView;
