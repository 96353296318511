import React from 'react';
import RegisterView from '../views/RegisterView';

const Register = () => {
  return (
    <div className="container">
      <RegisterView />
    </div>
  );
};

export default Register;
