import React from 'react';
import UserAttendanceView from '../views/UserAttendanceView';


const UserReport = () => {
    return (
      <div className="container">
        <UserAttendanceView />
      </div>
    );
  };
  
  export default UserReport;
