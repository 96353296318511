import React from 'react';
import CheckOutView from '../views/CheckOutView';
import UserAgentInfo from './UserAgentInfo';

const CheckOut = () => {
  return (
    <>
      <UserAgentInfo/>
      <CheckOutView />
  </>
     
 
  );
};

export default CheckOut;
