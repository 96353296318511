import React from 'react';
import CheckInView from '../views/CheckInView';
import UserAgentInfo from './UserAgentInfo';

const CheckIn = () => {
  return (
    <div className="container">
      <div className='col-md-12'><UserAgentInfo /></div>
      <CheckInView />
    </div>
  );
};

export default CheckIn;
