import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import API_URL from '../config';

const styles = StyleSheet.create({
    page: {
        padding: 20,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '12%',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: 4,
        fontSize: 10,
    },
});

const GenerateEmail = () => {
    const [attendance, setAttendance] = useState([]);
    const [branches, setBranches] = useState([]);
    const [users, setUsers] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [branchFilter, setBranchFilter] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const [filteredResults, setFilteredResults] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${API_URL}/users/users`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const fetchAttendance = async () => {
            try {
                const response = await axios.get(`${API_URL}/attendance/all`);
                setAttendance(response.data);
            } catch (error) {
                console.error('Error fetching attendance data:', error);
            }
        };

        const fetchBranches = async () => {
            try {
                const response = await axios.get(`${API_URL}/branches/branches`);
                setBranches(response.data.branches);
            } catch (error) {
                console.error('Error fetching branches:', error);
            }
        };

        fetchUsers();
        fetchAttendance();
        fetchBranches();
    }, []);

    const getUserDetails = (userId) => {
        if (!userId) return {};
        return users.find(user => user._id === userId) || {};
    };

    const getBranchName = (branchId) => {
        const branch = branches.find(branch => branch._id === branchId);
        return branch ? branch.name : 'Unknown Branch';
    };

    const calculateHoursWorked = (checkInTime, checkOutTime) => {
        if (!checkOutTime) return 'N/A';

        const checkIn = new Date(checkInTime);
        const checkOut = new Date(checkOutTime);

        const diffInMilliseconds = checkOut - checkIn;
        const diffInMinutes = diffInMilliseconds / 1000 / 60;

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = Math.floor(diffInMinutes % 60);

        return `${hours} hr${hours !== 1 ? 's' : ''} : ${minutes} min${minutes !== 1 ? 's' : ''}`;
    };

    const handleSearch = () => {
        const filtered = attendance.filter(record => {
            const user = record.userId || {};
            const userFullName = user.fullname || '';

            const matchesName = nameFilter === '' || userFullName.toLowerCase().includes(nameFilter.toLowerCase());
            const matchesBranch = branchFilter === '' || getBranchName(record.branchId) === branchFilter;
            const recordDate = new Date(record.timestamp);
            const matchesFromDate = !fromDate || recordDate >= new Date(fromDate);
            const matchesToDate = !toDate || recordDate <= new Date(toDate);

            return matchesName && matchesBranch && matchesFromDate && matchesToDate;
        }).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        setFilteredResults(filtered);
        setCurrentPage(1);
    };

    const handleNameFilterChange = (e) => {
        const value = e.target.value;
        setNameFilter(value);
        if (value.length > 0) {
            const filtered = users.filter(user =>
                user.fullname.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers([]);
        }
    };

    const handleUserSelect = (user) => {
        setNameFilter(user.fullname);
        setSelectedUser(user);
        setFilteredUsers([]);
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = selectedUser
        ? filteredResults.filter(record => record.userId?._id === selectedUser._id).slice(indexOfFirstRecord, indexOfLastRecord)
        : filteredResults.slice(indexOfFirstRecord, indexOfLastRecord);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    //Handle Emaling of report

    const handleEmail = () => {
        const email = 'fredokoririck@gmail.com';
        const subject = 'Attendance Report';
        const body = 'Please find attached the generated report?'; 

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Open the default mail application
        window.open(mailtoLink, '_blank');
    };


    const AttendancePDF = () => (
        <Document>
            <Page size="A4" orientation='landscape' style={styles.page}>
                <View>
                    <Text>Attendance Report</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>#</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Name</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Phone Number</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Branch</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Date</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Check-In</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Check-Out</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Hours Worked</Text></View>
                        </View>
                        {currentRecords.map((record, index) => {
                            const user = getUserDetails(record.userId?._id);
                            return (
                                <View style={styles.tableRow} key={record._id}>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{index + 1}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{user.fullname}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{user.phoneNumber || 'N/A'}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{getBranchName(record.branchId)}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{new Date(record.checkIn).toLocaleDateString()}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{new Date(record.checkIn).toLocaleTimeString()}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{record.checkOut ? new Date(record.checkOut).toLocaleTimeString() : 'N/A'}</Text></View>
                                    <View style={styles.tableCol}><Text style={styles.tableCell}>{calculateHoursWorked(record.checkIn, record.checkOut)}</Text></View>
                                </View>
                            );
                        })}
                    </View>
                </View>
            </Page>
        </Document>
    );

    const downloadPdf = async () => {
        const blob = await pdf(<AttendancePDF />).toBlob();
        saveAs(blob, 'attendance_report.pdf');
    };

    return (
        <div className="container mt-4">
            <h2>Attendance Table</h2>

            <div className="d-flex flex-wrap mb-3">
                <p>Filter: </p>
                <div className="p-2 position-relative">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Filter by Name"
                        value={nameFilter}
                        onChange={handleNameFilterChange}
                    />
                    {filteredUsers.length > 0 && (
                        <ul className="list-group position-absolute w-100" style={{ zIndex: 1000 }}>
                            {filteredUsers.map(user => (
                                <li
                                    key={user._id}
                                    className="list-group-item list-group-item-action"
                                    onClick={() => handleUserSelect(user)}
                                >
                                    {user.fullname}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="p-2">
                    <select
                        className="form-control"
                        value={branchFilter}
                        onChange={(e) => setBranchFilter(e.target.value)}
                    >
                        <option value="">Filter by Branch</option>
                        {branches.map((branch, index) => (
                            <option key={index} value={branch.name}>
                                {branch.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="p-2">
                    <input
                        type="date"
                        className="form-control"
                        placeholder="From Date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </div>
                <div className="p-2">
                    <input
                        type="date"
                        className="form-control"
                        placeholder="To Date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </div>
                <div className="p-2">
                    <button className="btn btn-primary" onClick={handleSearch}>Filter</button>
                </div>
               
               
                <div className="p-2">
                    <button className="btn btn-primary" onClick={downloadPdf}>Download PDF</button>
                </div>
                <div className="p-2">
                    
                       
                        <button className="btn btn-primary" onClick={handleEmail}>Send to Email</button>
                   
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Phone Number</th>
                            {/* <th scope="col">Email</th> */}
                            <th scope="col">Branch</th>
                            <th scope="col">Date</th>
                            <th scope="col">Check-In Time</th>
                            <th scope="col">Check-Out Time</th>
                            <th scope="col">Hours Worked</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecords.map((record, index) => {
                            const user = getUserDetails(record.userId?._id);
                            return (
                                <tr key={record._id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{user.fullname ?? 'Unknown User'}</td>
                                    <td>{user.phoneNumber ?? 'N/A'}</td>
                                    {/* <td>{user.email || 'N/A'}</td> */}
                                    <td>{getBranchName(record.branchId)}</td>
                                    <td>{new Date(record.checkIn).toLocaleDateString()}</td>
                                    <td>{new Date(record.checkIn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                                    <td>{record.checkOut ? new Date(record.checkOut).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }) : 'N/A'}</td>
                                    <td>{calculateHoursWorked(record.checkIn, record.checkOut)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <nav aria-label="Pagination">
                <ul className="pagination justify-content-center">
                    {Array.from({ length: Math.ceil(filteredResults.length / recordsPerPage) }, (_, i) => (
                        <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default GenerateEmail;
