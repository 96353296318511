import React, { useState, useEffect } from 'react';

export default function SuccessCheckOut() {
    const [checkOutTime, setCheckInTime] = useState('');

    useEffect(() => {
        const now = new Date();
        const formattedTime = now.toLocaleString(); // Format the date and time
        setCheckInTime(formattedTime);
    }, []);

    return (
        <>
            <h4>Check In successful</h4>
            <p>You have checked Out at: {checkOutTime}</p>
        </>
    );
}
