import React from 'react';
import { Link } from 'react-router-dom';
import UserAgentInfo from './UserAgentInfo';
import AttendanceChart from '../views/AttendanceChartView';

const Dashboard = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {/* User Agent Information */}
        <div className='col-md-12 mb-4'>
          <div className="card bg-light shadow">
            <div className="card-body">
              <UserAgentInfo />
            </div>
          </div>
        </div>
        {/* Seperator */}
        <hr className='hr'></hr>
        {/* Dummy Tiles */}
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow">
            <div className="card-body">
              <h5 className="card-title font-weight-bold">MY ATTENDANCE</h5>
              <hr/>
              <Link to="/UserAttendance" className="btn btn-primary">View My Attendance</Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow">
            <div className="card-body">
              <h5 className="card-title">Tile 2</h5>
              <p className="card-text">Some dummy content for Tile 2.</p>
              <Link to="/component2" className="btn btn-primary">Go to Component 2</Link>
            </div>
          </div>
        </div>
        <hr/>
        <AttendanceChart/>
      </div>
    </div>
  );
};

export default Dashboard;
