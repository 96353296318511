import React, { useState, useEffect } from 'react';

export default function SuccessCheckIn() {
    const [checkOutTime, setCheckOutTime] = useState('');

    useEffect(() => {
        const now = new Date();
        const formattedTime = now.toLocaleString(); // Format the date and time
        setCheckOutTime(formattedTime);
    }, []);

    return (
        <div style={styles.card}>
            <h3 style={styles.title}>Success</h3>
            <div style={styles.content}>
                <h4 color='green'>Check In successful</h4>
                <p>Check In time: {checkOutTime}</p>
            </div>
        </div>
    );
}

const styles = {
    card: {
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '500px',
        margin: '20px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        margin: '0 0 10px',
        fontSize: '24px',
        textAlign: 'center',
        color: '#333',
    },
    content: {
        textAlign: 'center',
    },
};
