import React from 'react';
import UpdateUserRoleForm from '../views/UpdateUserView';
import UserAgentInfo from './UserAgentInfo';

const UpdateUser = () => {
  return (
    <div className="container">
      <div className='col-md-12'><UserAgentInfo /></div>
      <UpdateUserRoleForm />
    </div>
  );
};

export default UpdateUser;
